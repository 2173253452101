import { Link } from "gatsby"
import React from "react"

export const DropDown = () => {
  return (
    <div className="navBarContainer__items-dropdown-container">
      <ul className="navBarContainer__items-dropdown-menu">
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/inmunologia"
          >
            Inmunología
          </Link>
        </li>
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/hormonas_y_marcadores"
          >
            Hormonas y Marcadores
          </Link>
        </li>
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/bacteriologia"
          >
            Bacteriología
          </Link>
        </li>
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/quimica_clinica"
          >
            Quimica Clínica
          </Link>
        </li>
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/uroanalisis"
          >
            Uroanalísis
          </Link>
        </li>
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/coprologia"
          >
            Coprología
          </Link>
        </li>
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/coagulacion"
          >
            Coagulación
          </Link>
        </li>
        <li>
          <Link
            className="navBarContainer__items-dropdown-link"
            to="/hematologia"
          >
            Hematología
          </Link>
        </li>
      </ul>
    </div>
  )
}
