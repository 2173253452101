import * as React from "react"
import { Helmet } from "react-helmet";




const Layout = ({ children }) => {
  

  return (
    <main className="default-layout" style={{position: "relative"}}>
       {/*  REACT HELMET FOR ADDING SITE META TAGS */}
       <Helmet> 
          <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Spartan:wght@400;700&display=swap" rel="stylesheet"></link> <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto+Condensed:wght@400;700&family=Spartan:wght@400;700&display=swap" rel="stylesheet"></link>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Work+Sans&display=swap" rel="stylesheet"></link>
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet"></link>            
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto+Condensed:wght@400;700&family=Work+Sans:wght@500&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Spartan:wght@400;700&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet"></link>
      </Helmet>
      
      { children }{/* INCLUDING CHILDREN */}

    </main>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout;



