import { getFunctions, httpsCallable } from "firebase/functions"
import app from "gatsby-plugin-firebase-v9.0"
import { Form, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import "yup-phone-lite"
import { TextArea } from "../../../form/TextArea"
import { useConsultAvailability } from "../../../store/ConsultAvailabilityStore"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"
import { navigate } from "gatsby"
import { getQueryParams } from "moderm-query-params"

const schema = Yup.object().shape({
  message: Yup.string().required("Mensaje requerido"),
})

export const Message = ({ previousStep, close }) => {
  const data = useConsultAvailability()
  const setData = useConsultAvailability(state => state.setData)
  const incrementPercent = useConsultAvailability(
    state => state.incrementPercent
  )

  const functions = getFunctions(app)

  const genericConsultar = params => {
    const callable = httpsCallable(functions, "genericConsultar")
    return callable(params)
  }

  return (
    <StepContainer>
      <Formik
        initialValues={{ message: "" }}
        onSubmit={values => {
          setData(values)
          incrementPercent(7)
          const params = getQueryParams()
          genericConsultar({
            ...data,
            ...values,
            ...params,
            date: data.date.toLocaleDateString("es-SV"),
          }).catch()
          window.dataLayer &&
            window.dataLayer.push({ event: "consult_form_sent" })
          close()
          navigate("/gracias")
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {() => (
          <Form noValidate autoComplete="off">
            <TextArea
              name="message"
              title="Mensaje o consulta"
              withAsterisk
              type="email"
              buttons={
                <ActionsButtons
                  enter
                  backStep={5}
                  newLine
                  idBack={"button-back-6"}
                  idNext={"button-next-6"}
                  nextText="Enviar"
                  onBack={() => {
                    previousStep && previousStep()
                  }}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </StepContainer>
  )
}
