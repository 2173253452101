import { Link } from "gatsby"
import React, { useState } from "react"
import { useConsultAvailability } from "../store/ConsultAvailabilityStore"
import { MobileDropdown } from "./ui/mobiledropdown"

// MobileNavbar COMPONENT THAT RECEIVES A STATE AND A FUNCTION FROM Navbar COMPONENT

const MobileNavbar = ({ mobileMenuActive, changeMobileMenu }) => {
  const [subMenuServicios, setSubMenuServicios] = useState(false)
  const onOpenModal = useConsultAvailability(state => state.onOpenModal)

  // FUNCTION TO SHOW MOBILE SUB MENU SERVICES
  const handleClickServicios = () => {
    setSubMenuServicios(!subMenuServicios)
  }

  return (
    <>
      <div
        className={
          mobileMenuActive
            ? "mobileNavContainer activeMobileMenu"
            : "mobileNavContainer"
        }
      >
        {/* STYLE APPLIED ACCORDING TO mobileMenuActive VALUE RECEIVED */}

        {/* ICON TO CLOSE*/}
        <div className="mobileNavContainer__closeBtn">
          <i
            className="fa-solid fa-xmark navBarMobileBtnClose"
            onClick={changeMobileMenu}
          ></i>
        </div>

        {/* MOBILE MENU LINKS CONTAINER*/}
        <div className="mobileNavContainer__items">
          {/* INICIO */}
          <Link to="/" className="mobileNavContainer__items-link">
            Inicio
          </Link>

          {/* SERVICIOS */}
          <div
            onClick={handleClickServicios}
            style={{ fontFamily: "Roboto Condensed" }}
            className={
              subMenuServicios
                ? "mobileNavContainer__items-link mobileServiciosLink mobileServiciosLinkActive"
                : "mobileNavContainer__items-link mobileServiciosLink"
            }
          >
            <span>Servicios</span>
            <i
              className={
                subMenuServicios
                  ? "fa-solid fa-chevron-down mobileDropIconActive"
                  : "fa-solid fa-chevron-down mobileDropIcon"
              }
            ></i>
          </div>
          <MobileDropdown state={subMenuServicios} />
          {/**DROPDOWN */}

          {/* CONTACTO */}
          <Link to="/contacto" className="mobileNavContainer__items-link">
            Contacto
          </Link>

          {/* CONSULTAR (TYPEFORM)
          <PopupButton id="snJxULSa">Consultar</PopupButton>*/}
          <button onClick={onOpenModal}>Consultar</button>
        </div>
      </div>
    </>
  )
}

export default MobileNavbar
