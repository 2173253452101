import { ErrorMessage, Field, useFormikContext } from "formik"
import React, { useRef } from "react"
import "react-phone-input-2/lib/style.css"
import TextareaAutosize from "react-textarea-autosize"

export const TextArea = ({
  withImage,
  image,
  title,
  withAsterisk,
  name,
  type = "text",
  buttons,
  placeholder = "Escribe tu respueta...",
  id,
  ...rest
}) => {
  const { errors } = useFormikContext()
  const ref = useRef()
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {withImage ? (
        <div
          //   style={{
          //     display: "flex",
          //     flexWrap: "wrap",
          //     justifyContent: "space-between",
          //     width: "80vw",
          //     alignItems: "center",
          //   }}
          className="input-img"
        >
          <div className="order-input">
            <h2>{title}</h2>
            <Field name={name} {...rest}>
              {({ field }) => (
                <TextareaAutosize
                  /* ref={ref} */
                  {...field}
                  id={id ? id : name}
                  name={name}
                  placeholder={placeholder}
                  className="input"
                  onKeyDown={e => {
                    const keyCode = e.which || e.keyCode
                    // 13 represents the Enter key
                    if (keyCode === 13 && !e.shiftKey) {
                      // Don't generate a new line
                      e.preventDefault()

                      //submit for on enter

                      ref.current?.click()
                    }
                  }}
                  maxRows={6}
                />
              )}
            </Field>
            <ErrorMessage
              name={name}
              component={() => (
                <div className="error">
                  <small>
                    <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                    {errors[name]}
                  </small>
                </div>
              )}
            />
            {buttons}
          </div>
          <div className="order-img">{image}</div>
        </div>
      ) : (
        <div className="only-input">
          <h2>{title}</h2>
          <Field name={name} {...rest}>
            {({ field }) => (
              <TextareaAutosize
                /* ref={ref} */
                {...field}
                id={id ? id : name}
                name={name}
                placeholder={placeholder}
                className="input"
                onKeyDown={e => {
                  const keyCode = e.which || e.keyCode
                  // 13 represents the Enter key
                  if (keyCode === 13 && !e.shiftKey) {
                    // Don't generate a new line
                    e.preventDefault()

                    //submit for on enter

                    ref.current?.click()
                  }
                }}
                maxRows={6}
              />
            )}
          </Field>
          <ErrorMessage
            name={name}
            component={() => (
              <div className="error">
                <small>
                  <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                  {errors[name]}
                </small>
              </div>
            )}
          />
          {buttons}
        </div>
      )}
      <button ref={ref} style={{ display: "none" }} type="submit"></button>
    </div>
  )
}
