import React from "react"
import { Field, useFormikContext } from "formik"
import { useState } from "react"
import { useEffect } from "react"

const abc = [
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
]

const Radio = ({ id, name, value, indx, label, type = "radio" }) => {
	const [selected, isSelected] = useState(false)
	const { values, submitForm } = useFormikContext()

	useEffect(() => {
		if (type === "radio") {
			isSelected(value === values[name])
		} else {
			isSelected(values[name]?.includes(value))
		}
	}, [name, value, type, values])

	useEffect(() => {
		if (selected && type === "radio") {
			submitForm()
		}
	}, [selected, type, submitForm])

	return (
		<label
			key={id}
			className={`form-control__radio-container ${
				selected ? "form-control__radio-container--selected" : ""
			}`}
			style={{
				boxShadow: isSelected
					? "box-shadow: rgb(60 60 60 / 80%) 0px 0px 0px 2px inset"
					: "initial",
			}}
		>
			<Field
				id={id}
				type={type}
				name={name}
				value={value}
				className="form-control__radio"
			/>
			<label htmlFor={id} className="form-control__label-radio">
				{abc[indx]}
			</label>

			<label htmlFor={id} className="form-control__label-radio-2">
				{label}
			</label>
			{/* <div
              style={{
                height: 22,
                width: 22,
              }}
            ></div> */}
		</label>
	)
}

export default Radio