import React from "react"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import StepWizard from "react-step-wizard"
import { useContact } from "../../store/ContactStore"
import { Email } from "./contact/Email"
import { FullName } from "./contact/FullName"
import { GetStarted } from "./contact/GetStarted"
import { MessageConsult } from "./contact/MessageConsult"
import { PhoneNumber } from "./contact/PhoneNumber"
import { Progress } from "./Progress"

export const Contact = ({ open, onCloseModal }) => {
  const percent = useContact(state => state.percent)
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      closeOnOverlayClick={false}
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
      }}
      blockScroll
    >
      <Progress width={`${percent}%`} />
      <StepWizard className="modal__container">
        <GetStarted />
        <FullName />
        <PhoneNumber />
        <Email />
        <MessageConsult close={onCloseModal} />
      </StepWizard>
    </Modal>
  )
}
