import { Form, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import { Input } from "../../../form/Input"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"
import "yup-phone-lite"
import { useContact } from "../../../store/ContactStore"
const schema = Yup.object().shape({
  email: Yup.string()
    .required("El correo electronico es obligatorio")
    .email("formato de correo electronico invalido"),
})

export const Email = ({ goToStep, previousStep }) => {
  const { setData } = useContact()
  const incrementPercent = useContact(state => state.incrementPercent)

  return (
    <StepContainer>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={values => {
          setData(values)
          goToStep(5)
          incrementPercent(4)
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {() => (
          <Form noValidate autoComplete="off">
            <Input
              name="email"
              title="Correo electrónico"
              withAsterisk
              type="email"
              placeholder="name@example.com"
              buttons={
                <ActionsButtons
                  enter
                  idBack={"button-back-3"}
                  idNext={"button-next-3"}
                  onBack={() => {
                    previousStep && previousStep()
                    incrementPercent(2)
                  }}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </StepContainer>
  )
}
