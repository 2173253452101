import React from "react"

export const Progress = ({ width }) => {
  return (
    <div
      className="percent"
      style={{
        width,
      }}
    ></div>
  )
}
