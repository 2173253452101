import { ErrorMessage, Field, useFormikContext } from "formik"
import React from "react"

export const Input = ({
  withImage,
  image,
  title,
  withAsterisk,
  name,
  type = "text",
  buttons,
  placeholder = "Escribe tu respueta...",
  ...rest
}) => {
  const { errors } = useFormikContext()

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {withImage ? (
        <div
          //   style={{
          //     display: "flex",
          //     flexWrap: "wrap",
          //     justifyContent: "space-between",
          //     width: "80vw",
          //     alignItems: "center",
          //   }}
          className="input-img"
        >
          <div className="order-input">
            <h2>{title}</h2>
            <Field type={type} placeholder={placeholder} name={name} />
            <ErrorMessage
              name={name}
              component={() => (
                <div className="error">
                  <small>
                    <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                    {errors[name]}
                  </small>
                </div>
              )}
            />
            {buttons}
          </div>
          <div className="order-img">{image}</div>
        </div>
      ) : (
        <div className="only-input">
          <h2>{title}</h2>
          <Field type={type} placeholder={placeholder} name={name} />
          <ErrorMessage
            name={name}
            component={() => (
              <div className="error">
                <small>
                  <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                  {errors[name]}
                </small>
              </div>
            )}
          />
          {buttons}
        </div>
      )}
    </div>
  )
}
