import React from 'react';
import { Link } from 'gatsby';

export const MobileDropdown = ( { state } ) => {

  
  return (
    <>
      {/* LINKS */}
      <div className= {state ? "containerMobileDropdown mobileDropActivated" : "containerMobileDropdown" }>{/* STYLE APPLIED ACCORDING TO THE STATE VALUE RECEIVED */}
            <Link to="/inmunologia" className="mobileDropdown-item">
              Inmunología
            </Link>
          
            <Link to="/hormonas_y_marcadores" className="mobileDropdown-item">
              Hormonas y Marcadores
            </Link>
                  
            <Link to="/bacteriologia" className="mobileDropdown-item">
              Bacteriología
            </Link>
                    
            <Link to="/quimica_clinica" className="mobileDropdown-item">
              Quimica Clínica
            </Link>
        
            <Link to="/uroanalisis" className="mobileDropdown-item">
              Uroanálisis
            </Link>
                    
            <Link to="/coprologia" className="mobileDropdown-item">
              Coprología
            </Link>
                  
            <Link to="/coagulacion" className="mobileDropdown-item">
              Coagulación
            </Link>
                    
            <Link to="/hematologia" className="mobileDropdown-item">
              Hematología
            </Link>
                  
      </div>
    </>
  )
}
