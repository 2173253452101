import create from "zustand"

export const useConsultAvailability = create(set => ({
  fullName: "",
  totalSteps: 7,
  currStep: 0,
  percent: 0,
  utm_source: "XXXXXX",
  utm_medium: "XXXXXX",
  utm_campaign: "XXXXXX",
  utm_term: "XXXXXX",
  open: false,
  onOpenModal: () => set(() => ({ open: true })),
  onCloseModal: () => set(() => ({ open: false })),
  setData: data => set(() => ({ ...data })),
  incrementPercent: currStep =>
    set(state => ({ percent: (currStep / state.totalSteps) * 100, currStep })),
}))
