import React from "react"
import { ErrorMessage, useFormikContext } from "formik"
import Radio from "./Radio"

const Radios = ({
  label,
  name,
  options = [],
  subLabel,
  buttons,
  type = "radio",
  ...rest
}) => {
  const { errors } = useFormikContext()

  return (
    <div className="form-control">
      <p className="form-control__label">{label}</p>
      <span className="form-control__sublabel" style={{ textAlign: "justify" }}>
        {subLabel}
      </span>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: 'repeat(2,1fr)',
          gap: '8px',
          
        }}
      >
        {type === "checkbox" && (
          <span style={{ fontSize: "14px" }}>Choose as many as you like</span>
        )}
        {options.map((option, indx) => (
          <Radio
            key={option.id}
            {...option}
            indx={indx}
            name={name}
            type={type}
          />
        ))}
      </div>
      <ErrorMessage
        name={name}
        component={() => (
          <div className="form-control__error">
            <small>
              <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
              {errors[name]}
            </small>
          </div>
        )}
      />
            {buttons}
    </div>
  )
}

export default Radios
