import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { DropDown } from "../../components/ui/dropdown"
import { useConsultAvailability } from "../../store/ConsultAvailabilityStore"
import MobileNavbar from "../mobileNavbar"
import { ConsultAvailability } from "../modals/ConsultAvailability"

const NavBar = () => {
  //State
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const onOpenModal = useConsultAvailability(state => state.onOpenModal)
  // const [open, setOpen] = useState(false)

  // const onOpenModal = () => setOpen(true)
  // const onCloseModal = () => setOpen(false)

  const changeMobileMenu = () => {
    setMobileMenuActive(!mobileMenuActive)
  }

  return (
    <>
      <div>
        <div className="navBarContainer">
          <div className="navBarContainer__logoanditems">
            <div className="navBarContainer__logo">
              <Link to="/">
                <StaticImage
                  quality={90}
                  src="../../images/NavLogo.png"
                  layout="constrained"
                  placeholder="none"
                  alt="LaboratorioGS_Logo"
                />
              </Link>
            </div>
            <div className="navBarContainer__items">
              <Link to="/" className="navBarContainer__items-link">
                Inicio
              </Link>
              <div className="navBarContainer__items-link navBarContainer__items-dropdown">
                Servicios
                <i
                  className="fa-solid fa-chevron-down"
                  style={{ marginLeft: "6px" }}
                ></i>
                <DropDown />
              </div>
              <Link to="/contacto" className="navBarContainer__items-link">
                Contacto
              </Link>
            </div>
          </div>

          <div className="navBarContainer__btnConsultar">
            {/* <PopupButton
              id="snJxULSa"
              className="navBarContainer__btnConsultar-btn"
            >
              Consultar
            </PopupButton> */}
            <button
              className="navBarContainer__btnConsultar-btn"
              onClick={onOpenModal}
            >
              Consultar
            </button>
            <ConsultAvailability />
          </div>
          <div className="navBarnavBarContainer__btnNavbarMobileCont">
            <i
              className="fas fa-bars navBarMobileBtn"
              onClick={changeMobileMenu}
            ></i>
          </div>

          {/* Component for Mobile Menu */}
          <MobileNavbar
            mobileMenuActive={mobileMenuActive}
            changeMobileMenu={changeMobileMenu}
          />
        </div>
      </div>
    </>
  )
}

export default NavBar
