import { ErrorMessage, Field, useFormikContext } from "formik"
import React, { useRef } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

export const Phone = ({
  withImage,
  image,
  title,
  withAsterisk,
  name,
  type = "text",
  buttons,
  placeholder = "Escribe tu respueta...",
  ...rest
}) => {
  const { errors, values } = useFormikContext()

  const ref = useRef(null)

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {withImage ? (
        <div
          //   style={{
          //     display: "flex",
          //     flexWrap: "wrap",
          //     justifyContent: "space-between",
          //     width: "80vw",
          //     alignItems: "center",
          //   }}
          className="input-img"
        >
          <div className="order-input">
            <h2>{title}</h2>
            <Field name={name}>
              {({ form: { touched, errors, setFieldValue, setTouched } }) => {
                return (
                  <>
                    <PhoneInput
                      disableSearchIcon
                      disableDropdown
                      onlyCountries={["sv"]}
                      country={"sv"}
                      inputStyle={{
                        fontSize: "30px",
                        paddingLeft: "40px",
                      }}
                      inputClass="input"
                      containerStyle={{
                        width: "100%",
                      }}
                      name={name}
                      specialLabel=""
                      placeholder={placeholder}
                      className={`form-wrapper__control-input ${
                        errors[name] && "error"
                      }`}
                      onKeyDown={e => {
                        const keyCode = e.which || e.keyCode
                        // 13 represents the Enter key
                        if (keyCode === 13) {
                          ref.current?.click()
                        }
                      }}
                      enableSearch
                      onChange={(a, b, c, value) => {
                        setFieldValue(name, value)
                        if (!touched[name]) {
                          setTouched({ ...touched, [name]: true })
                        }
                      }}
                      onBlur={() => {
                        const value = values[name]
                        if (value.length === 0 && !touched[name]) {
                          setTouched({ ...touched, [name]: true })
                        }
                      }}
                    />
                  </>
                )
              }}
            </Field>
            <ErrorMessage
              name={name}
              component={() => (
                <div className="error">
                  <small>
                    <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                    {errors[name]}
                  </small>
                </div>
              )}
            />
            {buttons}
          </div>
          <div className="order-img">{image}</div>
        </div>
      ) : (
        <div className="only-input">
          <h2>{title}</h2>
          <Field name={name}>
            {({ form: { touched, errors, setFieldValue, setTouched } }) => {
              return (
                <>
                  <PhoneInput
                    disableSearchIcon
                    disableDropdown
                    onlyCountries={["sv"]}
                    country={"sv"}
                    inputStyle={{
                      fontSize: "30px",
                      paddingLeft: "40px",
                    }}
                    inputClass="input"
                    containerStyle={{
                      width: "100%",
                    }}
                    name={name}
                    specialLabel=""
                    placeholder={placeholder}
                    className={`form-wrapper__control-input ${
                      errors[name] && "error"
                    }`}
                    enableSearch
                    onKeyDown={e => {
                      const keyCode = e.which || e.keyCode
                      // 13 represents the Enter key
                      if (keyCode === 13) {
                        ref.current?.click()
                      }
                    }}
                    onChange={(a, b, c, value) => {
                      setFieldValue(name, value)
                      if (!touched[name]) {
                        setTouched({ ...touched, [name]: true })
                      }
                    }}
                    onBlur={() => {
                      const value = values[name]
                      if (value.length === 0 && !touched[name]) {
                        setTouched({ ...touched, [name]: true })
                      }
                    }}
                  />
                </>
              )
            }}
          </Field>
          <ErrorMessage
            name={name}
            component={() => (
              <div className="error">
                <small>
                  <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                  {errors[name]}
                </small>
              </div>
            )}
          />
          {buttons}
        </div>
      )}
      <button type="submit" ref={ref} style={{ display: "none" }}></button>
    </div>
  )
}
