import React from "react"
import { useEffect } from "react"
import { useContact } from "../../../store/ContactStore"
import { StepContainer } from "../StepContainer"

export const GetStarted = ({ goToStep }) => {
  const incrementPercent = useContact(state => state.incrementPercent)
  useEffect(() => {
    incrementPercent(0)
  }, [incrementPercent])
  return (
    <StepContainer>
      <svg width={64} height={64} viewBox="0 0 32 32">
        <path
          fill="#023564"
          clipRule="#023564"
          d="M16,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S19.3,14,16,14z M16,4c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S18.2,4,16,4z       M29,30H3c-0.6,0-1-0.4-1-1v-3c0-7.7,6.3-14,14-14s14,6.3,14,14v3C30,29.6,29.6,30,29,30z M4,28h24v-2c0-6.6-5.4-12-12-12  S4,19.4,4,26V28z       M10,24c-0.6,0-1-0.4-1-1v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v4C11,23.6,10.6,24,10,24z       M12,22H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S12.6,22,12,22z"
        />
      </svg>

      <h2
        style={{
          margin: "0px",
          maxWidth: "100%",
          width: "inherit",
          fontWeight: "unset",
          fontSize: "36px",
          lineHeight: "44px",
          color: "rgb(0, 0, 0)",
          textAlign: "center",
        }}
      >
        Contactanos
      </h2>
      <div>
        <button
          onClick={() => {
            goToStep(2)
            incrementPercent(1)
          }}
        >
          Iniciar
        </button>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
        <svg width={11} height={11} viewBox="0 0 12 12">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6ZM5.99033 2.59455C5.93953 2.31465 5.69455 2.1024 5.4 2.1024C5.06863 2.1024 4.8 2.37103 4.8 2.7024V6.6L4.80967 6.70785C4.86047 6.98775 5.10545 7.2 5.4 7.2H7.8L7.90785 7.19033C8.18775 7.13953 8.4 6.89455 8.4 6.6C8.4 6.26863 8.13137 6 7.8 6H6V2.7024L5.99033 2.59455Z"
          />
        </svg>

        <small> Dura 1 minuto</small>
      </div>
    </StepContainer>
  )
}
