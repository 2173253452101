import { Form, Formik } from "formik"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import * as Yup from "yup"
import "yup-phone-lite"
import { Input } from "../../../form/Input"
import { useConsultAvailability } from "../../../store/ConsultAvailabilityStore"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"

const schema = Yup.object().shape({
  fullName: Yup.string()
    .required("Nombre completo es requerido")
    .max(40, "El nombre excede los 40 caracteres")
    .matches(/^[a-zA-Z ]*$/, "Por favor ingresa un nombre"),
})

export const FullName = ({ goToStep, previousStep }) => {
  const { setData } = useConsultAvailability()

  const incrementPercent = useConsultAvailability(
    state => state.incrementPercent
  )

  return (
    <StepContainer>
      <Formik
        initialValues={{ fullName: "" }}
        onSubmit={values => {
          setData(values)
          goToStep(3)
          incrementPercent(2)
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {() => (
          <Form noValidate autoComplete="off">
            <Input
              id="fullName"
              name="fullName"
              title="Nombre completo"
              withAsterisk
              withImage
              buttons={
                <ActionsButtons
                  enter
                  backStep={0}
                  idBack={"button-back-1"}
                  idNext={"button-next-1"}
                  onBack={() => {
                    previousStep && previousStep()
                  }}
                />
              }
              image={
                <StaticImage
                  alt="Imagen lab2"
                  data-qa-loaded="true"
                  src="../../../images/fullname.png"
                  className="img"
                  layout="constrained"
                />
              }
            />
          </Form>
        )}
      </Formik>
    </StepContainer>
  )
}
