import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useEffect } from "react"
import { useConsultAvailability } from "../../../store/ConsultAvailabilityStore"
import { StepContainer } from "../StepContainer"

export const GetStarted = ({ goToStep }) => {
  const incrementPercent = useConsultAvailability(
    state => state.incrementPercent
  )

  useEffect(() => {
    incrementPercent(0)
  }, [incrementPercent])

  return (
    <StepContainer>
      <StaticImage
        src="../../../images/default-firstframe.png"
        width={240}
        height={240}
        layout="constrained"
      />
      <h2
        style={{
          margin: "0px",
          maxWidth: "100%",
          width: "inherit",
          fontWeight: "unset",
          fontSize: "36px",
          lineHeight: "44px",
          color: "rgb(0, 0, 0)",
          textAlign: "center",
        }}
      >
        Consultar Disponibilidad
      </h2>
      <button
        onClick={() => {
          goToStep(2)
          incrementPercent(1)
        }}
      >
        Consultar
      </button>
      <small>Dura 1 minuto</small>
    </StepContainer>
  )
}
