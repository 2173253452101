import { Form, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import "yup-phone-lite"
import { Phone } from "../../../form/Phone"
import { useConsultAvailability } from "../../../store/ConsultAvailabilityStore"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"

const schema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("el numero de telefono es obligatorio")
    .phone("SV", "Por favor digite un numero telefonico valido"),
})

export const PhoneNumber = ({ goToStep, previousStep }) => {
  const { setData } = useConsultAvailability()
  const incrementPercent = useConsultAvailability(
    state => state.incrementPercent
  )

  return (
    <StepContainer>
      <Formik
        initialValues={{ phoneNumber: "" }}
        onSubmit={values => {
          setData(values)
          goToStep(4)
          incrementPercent(3)
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {() => (
          <Form noValidate autoComplete="off">
            <Phone
              name="phoneNumber"
              title="Numero de telefono"
              withAsterisk
              buttons={
                <ActionsButtons
                  enter
                  backStep={1}
                  idBack={"button-back-2"}
                  idNext={"button-next-2"}
                  onBack={() => {
                    previousStep && previousStep()
                  }}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </StepContainer>
  )
}
