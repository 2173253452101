import { ErrorMessage, useFormikContext } from "formik"
import React, { useEffect, useState } from "react"

export const InputDate = ({
  withImage,
  image,
  title,
  withAsterisk,
  name,
  type = "text",
  buttons,
  placeholder = "Escribe tu respueta...",
  ...rest
}) => {
  const { errors, setFieldValue } = useFormikContext()
  const [day, setDay] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    const dayState =
      day !== undefined ? (!`${day}`.startsWith("0") ? `0${day}` : day) : "01"
    const monthState =
      month !== undefined
        ? !`${month}`.startsWith("0")
          ? `0${month}`
          : month
        : "01"
    const yearState = year

    const dateState = `${yearState}-${monthState}-${dayState}`
    setFieldValue(name, new Date(dateState))
  }, [day, month, year])

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {withImage ? (
        <div
          //   style={{
          //     display: "flex",
          //     flexWrap: "wrap",
          //     justifyContent: "space-between",
          //     width: "80vw",
          //     alignItems: "center",
          //   }}
          className="input-img"
        >
          <div className="order-input">
            <h2>{title}</h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <label className="input-label" htmlFor="day">
                  Día
                </label>
                <input
                  type="number"
                  step={1}
                  max={31}
                  id="day"
                  placeholder="DD"
                  defaultValue={day}
                  min={1}
                  onChange={e => {
                    setDay(e.target.value)
                  }}
                />
              </div>
              <span className="span">/</span>
              <div>
                <label className="input-label" htmlFor="month">
                  Mes
                </label>
                <input
                  type="number"
                  step={1}
                  max={12}
                  min={1}
                  id="month"
                  placeholder="MM"
                  defaultValue={month}
                  onChange={e => {
                    setMonth(e.target.value)
                  }}
                />
              </div>
              <span className="span">/</span>
              <div>
                <label className="input-label" htmlFor="year">
                  Año
                </label>
                <input
                  type="number"
                  step={1}
                  max={2099}
                  min={new Date().getFullYear()}
                  id="year"
                  placeholder="AAAA"
                  defaultValue={year}
                  onChange={e => {
                    setYear(e.target.value)
                  }}
                />
              </div>
            </div>

            <ErrorMessage
              name={name}
              component={() => (
                <div className="error">
                  <small>
                    <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                    {errors[name]}
                  </small>
                </div>
              )}
            />

            {buttons}
          </div>
          <div className="order-img">{image}</div>
        </div>
      ) : (
        <div className="only-input">
          <h2>{title}</h2>
          {/* <Field type={type} placeholder={placeholder} name={name} /> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <label className="input-label" htmlFor="day">
                Día
              </label>
              <input
                type="number"
                step={1}
                max={31}
                id="day"
                placeholder="DD"
                defaultValue={day}
                min={1}
                onChange={e => {
                  setDay(e.target.value)
                }}
              />
            </div>
            <span className="span">/</span>
            <div>
              <label className="input-label" htmlFor="month">
                Mes
              </label>
              <input
                type="number"
                step={1}
                max={12}
                min={1}
                id="month"
                placeholder="MM"
                defaultValue={month}
                onChange={e => {
                  setMonth(e.target.value)
                }}
              />
            </div>
            <span className="span">/</span>
            <div>
              <label className="input-label" htmlFor="year">
                Año
              </label>
              <input
                type="number"
                step={1}
                max={2099}
                min={new Date().getFullYear()}
                id="year"
                placeholder="AAAA"
                defaultValue={year}
                onChange={e => {
                  setYear(e.target.value)
                }}
              />
            </div>
          </div>

          <ErrorMessage
            name={name}
            component={() => (
              <div className="error">
                <small>
                  <i className="fa-solid fa-circle-exclamation"></i>&nbsp;
                  {errors[name]}
                </small>
              </div>
            )}
          />
          {buttons}
        </div>
      )}
    </div>
  )
}
