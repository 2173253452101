import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Contact } from "./modals/Contact"

const Footer = () => {
  // //  GRAPHQL TO GET THE FOOTER ICON
  // const logoFooter = useStaticQuery(graphql`
  //     query{
  //         file(relativePath: {eq: "logo lab blanco 2.png"}) {
  //             childImageSharp {
  //                 gatsbyImageData(
  //                     layout: CONSTRAINED
  //                     placeholder: TRACED_SVG
  //                     blurredOptions: {width: 20}
  //                   )
  //             }
  //         }
  //     }
  // `);

  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <>
      {/* MAIN FOOTER CONTAINER */}
      <div className="footerContainer">
        <div className="dividerFooter"></div>
        {/**DIVIDER */}

        {/* MAIN CONTENT */}
        <div className="ndContFooter">
          {/* FIRST SECTION (LOGO AND TEXT) */}
          <div className="ndContFooter1">
            <StaticImage
              src="../images/logo lab blanco 2.png"
              placeholder="tracedSVG"
              width={250}
              layout="constrained"
              className="middle-banner__image-mobile"
              alt="white-logo-laboratorioclinico"
            />
            <br />
            <p>
              Somos una empresa, donde realizamos todo tipo de Análisis
              Clínicos, realizados por licenciados con mas de 10 años de
              experiencia, responsables, con el mejor servicio al cliente,
              contamos con parqueo para visitantes.
            </p>
          </div>

          {/* SECOND SECTION ("Contactanos") */}
          <div className="ndContFooter2">
            <h1>Contáctanos</h1>
            <br />
            <h2 className="infoContact">
              <a href="tel:2520-2947">Teléfono: 2520-2947</a>
            </h2>
            <br />
            <h2 className="infoContact">
              <a href="mailto:info@laboratorioclinico.sv">
                info@laboratorioclinico.sv
              </a>
            </h2>
            <br />
            <br />
            <h2>
              Manténgase conectado con nuestro mercado por medio de correo
              electrónico mensual y actualizaciones
            </h2>
          </div>

          {/* THIRD SECTION */}
          <div className="ndContFooter3">
            <h1>Orgullosamente sirviendo al gran San Salvador</h1>
            <br />
            <div className="wrapperContactBtnfooter">
              {/* TYPEFORM BUTTON */}
              <button className="contactBtnFooter" onClick={onOpenModal}>
                Contactanos
              </button>
              <Contact onCloseModal={onCloseModal} open={open} />
            </div>
          </div>
        </div>

        <div className="dividerFooter"></div>
        {/**DIVIDER */}

        {/* SOCIAL MEDIA CONTAINER */}
        <div className="lastContFooter">
          <div className="socialMediaFooter">
            <a
              href="https://www.facebook.com/GS-Laboratorio-Clinico-103347995656904/?ti=as"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../images/facebookIcon.png"
                layout="fixed"
                alt="facebook-icon-laboratorioclinico"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
