import { useFormikContext } from "formik"
import React from "react"
import { useConsultAvailability } from "../../store/ConsultAvailabilityStore"

export const ActionsButtons = ({
  onNextStep,
  onBack,
  backText = "Atras",
  nextText = "Aceptar",
  idNext,
  idBack,
  backStep,
  enter = false,
  newLine = false,
}) => {
  const { setErrors } = useFormikContext()
  const incrementPercent = useConsultAvailability(
    state => state.incrementPercent
  )

  return (
    <div
      className="modal__actions"
      style={{ display: "flex", flexDirection: "column", gap: 4 }}
    >
      {newLine && (
        <div
          style={{
            width: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            textAlign: "start",
          }}
        >
          <small>
            Pulsa <span style={{ fontWeight: "bold" }}>Shift ⇧ + Enter ↵</span>{" "}
            para añadir un parrafo
          </small>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            fontWeight: "bold",
          }}
        >
          <button
            id={idBack}
            type="button"
            onClick={() => {
              setErrors({})
              onBack && onBack()
              incrementPercent(backStep)
            }}
          >
            {backText}
            <i
              className="fa-solid fa-arrow-left"
              style={{ color: "white", marginLeft: "0.5rem" }}
            ></i>
          </button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            fontWeight: "bold",
          }}
          className="btn-next"
        >
          <button type="submit" id={idNext}>
            {nextText}
            <i
              className="fa-solid fa-check"
              style={{ color: "white", marginLeft: "0.5rem" }}
            ></i>
          </button>
          {enter ? (
            <div
              style={{
                width: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <small style={{ fontWeight: "normal" }}>
                Pulsa <span style={{ fontWeight: "bold" }}>Enter ↵</span>
              </small>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
