import create from "zustand"

export const useContact = create(set => ({
  fullName: "",
  totalSteps: 5,
  currStep: 0,
  percent: 0,
  utm_source: "XXXXXX",
  utm_medium: "XXXXXX",
  utm_campaign: "XXXXXX",
  utm_term: "XXXXXX",
  setData: data => set(() => ({ ...data })),
  incrementPercent: currStep =>
    set(state => ({ percent: (currStep / state.totalSteps) * 100 })),
}))
