import { Form, Formik } from "formik"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import * as Yup from "yup"
import { Input } from "../../../form/Input"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"
import "yup-phone-lite"
import { useContact } from "../../../store/ContactStore"
const schema = Yup.object().shape({
  fullName: Yup.string()
    .required("El nombre es obligatorio")
    .max(40, "El nombre no debe tener mas de 40 characteres")
    .matches(/^[a-zA-Z ]*$/, "por favor escriba un nombre valido"),
})

export const FullName = ({ goToStep, previousStep }) => {
  const { setData } = useContact()
  const incrementPercent = useContact(state => state.incrementPercent)

  return (
    <StepContainer>
      <Formik
        initialValues={{ fullName: "" }}
        onSubmit={values => {
          setData(values)
          goToStep(3)
          incrementPercent(2)
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {() => (
          <Form noValidate autoComplete="off">
            <Input
              name="fullName"
              title="Nombre Completo"
              withAsterisk
              withImage
              buttons={
                <ActionsButtons
                  enter
                  idBack={"button-back-1"}
                  idNext={"button-next-1"}
                  onBack={() => {
                    previousStep && previousStep()
                    incrementPercent(0)
                  }}
                />
              }
              image={
                <StaticImage
                  alt="contact step 2 img"
                  data-qa-loaded="true"
                  src="../../../images/contact-step-2.png"
                  width={600}
                  //className="img"
                  layout="constrained"
                />
              }
            />
          </Form>
        )}
      </Formik>
    </StepContainer>
  )
}
