import { Form, Formik } from "formik"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import * as Yup from "yup"
import "yup-phone-lite"
import { InputDate } from "../../../form/Date"
import { useConsultAvailability } from "../../../store/ConsultAvailabilityStore"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"

const actualYear = new Date()

const schema = Yup.object().shape({
  date: Yup.date()
    .min(actualYear, `No puedes ingresar la fecha actual o fechas pasadas`)
    .required("La fecha es requerida")
    .typeError("La fecha no es valida"),
})

export const DateForm = ({ goToStep, previousStep }) => {
  const { setData } = useConsultAvailability()
  const incrementPercent = useConsultAvailability(
    state => state.incrementPercent
  )
  return (
    <StepContainer>
      <Formik
        initialValues={{}}
        onSubmit={values => {
          setData(values)
          goToStep(6)
          incrementPercent(5)
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        <Form noValidate autoComplete="off">
          <InputDate
            name={"date"}
            title="¿En que fecha te gustaría visitarnos?"
            withAsterisk
            withImage
            buttons={
              <ActionsButtons
                enter
                backStep={3}
                idBack={"button-back-4"}
                idNext={"button-next-4"}
                onBack={() => {
                  previousStep && previousStep()
                  incrementPercent(4)
                }}
              />
            }
            image={
              <StaticImage
                alt="Imagen lab2"
                data-qa-loaded="true"
                src="../../../images/dateform.png"
                className="img"
                layout="constrained"
              />
            }
          />
        </Form>
      </Formik>
    </StepContainer>
  )
}
