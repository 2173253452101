import { Form, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"
import "yup-phone-lite"
import { TextArea } from "../../../form/TextArea"
import { useContact } from "../../../store/ContactStore"
import { navigate } from "gatsby"
import { getFunctions, httpsCallable } from "firebase/functions"
import app from "gatsby-plugin-firebase-v9.0"
import { getQueryParams } from "moderm-query-params"

const schema = Yup.object().shape({
  message: Yup.string().required("el mensaje es requerido"),
})

export const MessageConsult = ({ previousStep, close }) => {
  const { setData } = useContact()
  const data = useContact()
  const incrementPercent = useContact(state => state.incrementPercent)

  const functions = getFunctions(app)

  const genericContacto = params => {
    const callable = httpsCallable(functions, "genericContacto")
    return callable(params)
  }

  return (
    <StepContainer>
      <Formik
        initialValues={{ message: "" }}
        onSubmit={values => {
          setData(values)
          incrementPercent(5)
          const params = getQueryParams()
          genericContacto({
            ...data,
            ...params,
            ...values,
          }).catch()
          window.dataLayer &&
            window.dataLayer.push({ event: "contact_form_sent" })
          close()
          navigate("/gracias")
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {() => (
          <Form noValidate autoComplete="off">
            <TextArea
              name="message"
              title="Mensaje o Consulta"
              withAsterisk
              buttons={
                <ActionsButtons
                  enter
                  newLine
                  idBack={"button-back-4"}
                  idNext={"button-next-4"}
                  nextText="Enviar"
                  onBack={() => {
                    previousStep && previousStep()
                    incrementPercent(3)
                  }}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </StepContainer>
  )
}
