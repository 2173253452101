import React from "react"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import StepWizard from "react-step-wizard"
import { useConsultAvailability } from "../../store/ConsultAvailabilityStore"
import { DateForm } from "./consults-avaliability/DateForm"
import { Email } from "./consults-avaliability/Email"
import { FullName } from "./consults-avaliability/FullName"
import { GetStarted } from "./consults-avaliability/GetStarted"
import { Kind } from "./consults-avaliability/Kind"
import { Message } from "./consults-avaliability/Message"
import { PhoneNumber } from "./consults-avaliability/PhoneNumber"
import { Progress } from "./Progress"

export const ConsultAvailability = () => {
  const percent = useConsultAvailability(state => state.percent)
  const onCloseModal = useConsultAvailability(state => state.onCloseModal)
  const open = useConsultAvailability(state => state.open)

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      closeOnOverlayClick={false}
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
      }}
      blockScroll
    >
      <Progress width={`${percent}%`} />
      <StepWizard className="modal__container">
        <GetStarted />
        <FullName />
        <PhoneNumber />
        <Email />
        <DateForm />
        <Kind />
        <Message close={onCloseModal} />
      </StepWizard>
    </Modal>
  )
}
