import { Form, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import "yup-phone-lite"
import Radios from "../../../form/Radios"
import { useConsultAvailability } from "../../../store/ConsultAvailabilityStore"
import { ActionsButtons } from "../ActionsButtons"
import { StepContainer } from "../StepContainer"

const schema = Yup.object().shape({
  type: Yup.string().required("Seleccione una opcion"),
})

export const Kind = ({ previousStep, goToStep }) => {
  const { setData } = useConsultAvailability()
  const incrementPercent = useConsultAvailability(
    state => state.incrementPercent
  )

  return (
    <StepContainer>
      <Formik
        initialValues={{ type: "" }}
        onSubmit={values => {
          setData(values)
          goToStep(7)
          incrementPercent(6)
        }}
        validateOnChange={true}
        validationSchema={schema}
      >
        {() => (
          <Form noValidate autoComplete="off">
            <Radios
              label="¿Qué tipo de prueba necesita?*"
              name={"type"}
              options={[
                {
                  id: "messageAbout",
                  label: "Hormonas y Marcadores",
                  value: "Hormonas y Marcadores",
                },
                {
                  id: "messageAbout1",
                  label: "Inmunología",
                  value: "Inmunologia",
                },
                {
                  id: "messageAbout2",
                  label: "Bacteorología",
                  value: "Bacteorología",
                },
                {
                  id: "messageAbout3",
                  label: "Uroanálisis",
                  value: "Uroanálisis",
                },
                {
                  id: "messageAbout4",
                  label: "Hematología",
                  value: "Hematología",
                },
                {
                  id: "messageAbout5",
                  label: "Otro",
                  value: "Otro",
                },
              ]}
              buttons={
                <ActionsButtons
                  enter
                  backStep={4}
                  idBack={"button-back-5"}
                  idNext={"button-next-5"}
                  onBack={() => {
                    previousStep && previousStep()
                  }}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </StepContainer>
  )
}
